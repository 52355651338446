import React, { useState, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Countdown from '../../Countdown/Countdown';
import "./TimeCounter.css";

const TimeCounter = ({ name = "רעותי", day = 22, month = 3, year = 2023 }) => {
  const navigate = useNavigate()
  const location = useLocation();

  // useState Hooks
  const [state, setState] = useState();

  // get current time
  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  // get current year
  const birthdayDate = new Date(year, month - 1, day);
  const isItBdayOrAfter = new Date(birthdayDate.toDateString()) <= new Date(currentTime.toDateString());

  function daysUntilNext(month, day) {
    const today = new Date();
    const bday = new Date(today.getFullYear(), month - 1, day);
    if (today.getTime() > bday.getTime()) {
      bday.setFullYear(bday.getFullYear() + 1);
    }
    const diff = bday.getTime() - today.getTime();
    return diff;
  }

  useLayoutEffect(() => {
    const intervalId = setInterval(() => {
      isItBdayOrAfter && navigate("/today");
      const countdown = () => {
        const timeRemaining = daysUntilNext(month, day);
  
        let seconds = Math.floor(timeRemaining / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
  
        seconds %= 60;
        minutes %= 60;
        hours %= 24;
  
        // Setting States
        setState((prevState) => ({
          ...prevState,
          seconds,
          minutes,
          hours,
          days,
          isItBdayOrAfter,
        }));
      };
      if (!isItBdayOrAfter) {
        countdown();
      } else {
        setState((prevState) => ({
          ...prevState,
          isItBdayOrAfter: true,
        }));
      }
    }, 1000);
    
    // Return the cleanup function
    return () => clearInterval(intervalId);
  }, [currentYear, day, isItBdayOrAfter, month]);

  const birth = new Date(currentYear, month - 1, day);
  const monthNames = [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר',
  ];
  const monthBday = monthNames[birth.getMonth()];

  return (
    <div className='page'>
      {state && <>
        <Countdown countdownData={state} name={name} />
        <div className='birthdate'>
          {day} {monthBday} {currentYear}
        </div>
      </>}
    </div>
  );
};

export default TimeCounter;
