import React, { lazy, Suspense } from 'react';
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import TimeCounter from './components/Birthday/TimeCounter/TimeCounter';
import MainViewContextProvider from './contexts/MainViewContext';
const Content = lazy(() => import('./components/Content/Content'));
const Birthday = lazy(() => import('./components/Birthday/Birthday'))

function App() {

    return (
        <BrowserRouter className="App">
            <Routes>
                <Route exact path='/' element={<TimeCounter />} />
                <Route exact path='/today' element={
                    <Suspense fallback={
                        <div className="spinner" />
                    }>
                        <Birthday />
                    </Suspense>
                } />
                <Route exact path='/content' element={
                    <MainViewContextProvider>
                        <Suspense fallback={
                            <div className="spinner" />
                        }>
                            <Content />
                        </Suspense>
                    </MainViewContextProvider>
                } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
