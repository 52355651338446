const birthdayAudio = [
    {
        name: "יום הולדת",
        singer: "להקת עדן",
        cover: "1",
        coverFileType: "gif",
        musicSrc: "Eden-Birthday"
    },
    {
        name: "יום הולדת",
        singer: "אליעד נחום",
        cover: "2",
        musicSrc: "Eliad-Birthday"
    },
    {
        name: "Birthday",
        singer: "Katy Perry",
        cover: "3",
        musicSrc: "Katy Perry - Birthday"
    },
    {
        name: "למי יש יום הולדת",
        singer: "אורנה דץ ומשה דץ",
        cover: "4",
        musicSrc: "למי יש יום הולדת"
    },
    {
        name: "B-Day Song",
        singer: "Madonna",
        cover: "5",
        musicSrc: "B-Day Song"
    },
    {
        name: "איזה יום שמח",
        singer: "מיקי קם",
        cover: "6",
        musicSrc: "איזה יום שמח"
    },
    {
        name: "Celebration",
        singer: "Kool & The Gang",
        cover: "7",
        coverFileType: "gif",
        musicSrc: "Celebration"
    },
    {
        name: "It's My Party",
        singer: "Jessie J",
        cover: "8",
        musicSrc: "It's My Party"
    },
    {
        name: "חג לי",
        singer: "לאה נאור",
        cover: "9",
        coverFileType: "gif",
        musicSrc: "חג לי"
    },
    {
        name: "Happy, Happy Birthday",
        singer: "#Karma",
        cover: "10",
        musicSrc: "Happy, Happy Birthday"
    },
    {
        name: "יש לי יום הולדת",
        singer: "אחרית הימים",
        cover: "11",
        musicSrc: "יש לי יום הולדת"
    },
    {
        name: "Birthday",
        singer: "Anne-Marie",
        cover: "12",
        musicSrc: "Birthday"
    }
];
const ourAudio = [
    {
        name: "Nothing's Gonna Change My Love For You",
        singer: "Glenn Medeiros",
        cover: "3",
        coverFolder: "our",
        musicSrc: "Nothing's Gonna Change My Love For You"
    },
    {
        name: "מה שאת אוהבת",
        singer: "גלי עטרי",
        cover: "5",
        coverFolder: "reut",
        musicSrc: "מה שאת אוהבת"
    },
    {
        name: "איך אפשר שלא להתאהב בך",
        singer: "ג'יין בורדו",
        cover: "1",
        coverFolder: "liron",
        musicSrc: "איך אפשר שלא"
    },
    {
        name: "אהבה בסוף הקיץ",
        singer: "צביקה פיק",
        cover: "12",
        coverFolder: "our",
        musicSrc: "אהבה בסוף הקיץ"
    },
    {
        name: "קרן שמש",
        singer: "בניה ברבי",
        cover: "1",
        coverFolder: "our",
        musicSrc: "קרן שמש"
    },
    {
        name: "כל הכבוד",
        singer: "יהורם גאון",
        cover: "6",
        coverFolder: "our",
        musicSrc: "כל הכבוד"
    },
    {
        name: "זיקוקים",
        singer: "משה פרץ",
        cover: "18",
        coverFolder: "our",
        musicSrc: "זיקוקים"
    },
    {
        name: "חבק אותי",
        singer: "עילי בוטנר",
        cover: "5",
        coverFolder: "our",
        musicSrc: "חבק אותי"
    },
    {
        name: "Just The Way You Are",
        singer: "Bruno Mars",
        cover: "10",
        coverFolder: "our",
        musicSrc: "Just The Way You Are"
    },
    {
        name: "I'm Yours",
        singer: "Jason Mraz",
        cover: "9",
        coverFolder: "our",
        musicSrc: "I'm Yours"
    },
    {
        name: "אני לא מפסיק להתרגש ממך",
        singer: "דני רובס",
        cover: "1",
        coverFolder: "reut",
        musicSrc: "אני לא מפסיק להתרגש ממך"
    },
    {
        name: "איש של לילה",
        singer: "רוני דלומי",
        cover: "17",
        coverFolder: "our",
        musicSrc: "איש של לילה"
    },
    {
        name: "יש לי ציפור קטנה בלב",
        singer: "יגאל בשן",
        cover: "5",
        coverFolder: "liron",
        musicSrc: "יש לי ציפור קטנה בלב"
    },
    {
        name: "להשתטות לפעמים",
        singer: "גבי שושן",
        cover: "7",
        coverFolder: "our",
        musicSrc: "להשתטות לפעמים"
    },
    {
        name: "את יפה",
        singer: "עידן יניב",
        cover: "4",
        coverFolder: "reut",
        musicSrc: "את יפה"
    },
    {
        name: "Kiss Me",
        singer: "Sixpence",
        cover: "15",
        coverFolder: "our",
        musicSrc: "Kiss Me"
    },
    {
        name: "A Thousand Years",
        singer: "Christina Perri",
        cover: "2",
        coverFolder: "liron",
        musicSrc: "A Thousand Years"
    },
    {
        name: "ביחד",
        singer: "מרינה מקסימיליאן",
        cover: "16",
        coverFolder: "our",
        musicSrc: "ביחד"
    },
    {
        name: "הולכת איתך",
        singer: "נרקיס",
        cover: "6",
        coverFolder: "reut",
        musicSrc: "הולכת איתך"
    },
    {
        name: "Photograph",
        singer: "Ed Sheeran",
        cover: "4",
        coverFolder: "our",
        musicSrc: "Photograph"
    },
    {
        name: "Just The Two Of Us",
        singer: "Bill Withers",
        cover: "13",
        coverFolder: "our",
        musicSrc: "Just The Two Of Us"
    },
    {
        name: "אני שוב מתאהב",
        singer: "גידי גוב",
        cover: "8",
        coverFolder: "our",
        musicSrc: "אני שוב מתאהב"
    },
    {
        name: "כמה אהבה",
        singer: "קובי פרץ",
        cover: "2",
        coverFolder: "our",
        musicSrc: "כמה אהבה"
    },
    {
        name: "כל כך יפה לך",
        singer: "רותם כהן",
        cover: "2",
        coverFolder: "reut",
        musicSrc: "כל כך יפה לך"
    },
    {
        name: "רץ אלייך",
        singer: "עילי בוטנר",
        cover: "3",
        coverFolder: "liron",
        musicSrc: "רץ אלייך"
    },
    {
        name: "Love Me Like You Do",
        singer: "Ellie Goulding",
        cover: "11",
        coverFolder: "our",
        musicSrc: "Love Me Like You Do"
    },
    {
        name: "יסמין",
        singer: "הפיל הכחול",
        cover: "3",
        coverFolder: "reut",
        musicSrc: "יסמין"
    },
    {
        name: "Whenever, Wherever",
        singer: "Shakira",
        cover: "14",
        coverFolder: "our",
        musicSrc: "Whenever, Wherever"
    },
    {
        name: "את עוד תשני את העולם",
        singer: "לירון טל",
        cover: "7",
        coverFolder: "reut",
        musicSrc: "תשני את העולם"
    },
    {
        name: "שרק תחייך",
        singer: "רעות נאמני",
        cover: "4",
        coverFolder: "liron",
        musicSrc: "שרק תחייך"
    }
]

const baseFolderAudio = "/audio/";
const baseFolderCover = "/images/";

const finalBirthdayPlaylist = birthdayAudio.map(audio => {
    const { name, singer, cover, musicSrc, coverFileType } = audio;

    const coverFolder = `birthday/`;
    const finalCoverFileType = coverFileType ?? "jpg";
    const finalCoverSrc = `${baseFolderCover}${coverFolder}${cover}.${finalCoverFileType}`;

    const musicFolder = `birthday/`;
    const finalMusicFileType = "mp3";
    const finalMusicSrc = `${baseFolderAudio}${musicFolder}${musicSrc}.${finalMusicFileType}`;

    return {
        name, singer,
        cover: finalCoverSrc,
        musicSrc: finalMusicSrc
    }
})

const finalOurPlaylist = ourAudio.map(audio => {
    const { name, singer, cover, musicSrc, coverFileType, coverFolder } = audio;

    const finalCoverFileType = coverFileType ?? "jpg";
    const finalCoverSrc = `${baseFolderCover}${coverFolder}/${cover}.${finalCoverFileType}`;
    const musicFolder = `our/`;
    const finalMusicFileType = "mp3";
    const finalMusicSrc = `${baseFolderAudio}${musicFolder}${musicSrc}.${finalMusicFileType}`;

    return {
        name, singer,
        cover: finalCoverSrc,
        musicSrc: finalMusicSrc
    }
})

const playlists = [
    { name: "שירי יום הולדת", songs: finalBirthdayPlaylist },
    { name: "שירים משותפים", songs: finalOurPlaylist }
]
export default playlists;