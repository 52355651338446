import React, { createContext, useState, useRef, useEffect } from "react";
import playlists from 'utils/audioItems';
import ReactJkMusicPlayer from 'react-jinke-music-player'

export const MainViewContext = createContext();

const MainViewContextProvider = (props) => {
    const [viewedRefIndex, setViewedRefIndex] = useState(0);
    const [playlistIndex, setPlaylistIndex] = useState(0);
    const [hasBeenToPageIndex1, setHasBeenToPageIndex1] = useState(false);
    const audioRef = useRef();

    const handlePlaylistSwitch = () => {
        const nextPlaylistIndex = (playlistIndex + 1) % playlists.length;
        setPlaylistIndex(nextPlaylistIndex);
    };

    useEffect(() => {
        if ([0, 4].includes(viewedRefIndex)) audioRef.current.audio.pause();
        if (viewedRefIndex === 1 && !hasBeenToPageIndex1) {
            audioRef.current.audio.play();
            setHasBeenToPageIndex1(true);
        }
    }, [viewedRefIndex])
    const customLocale = {
        playModeText: {
            order: 'ניגון לפי הסדר',
            orderLoop: 'ניגון בלופ',
            singleLoop: 'לופ יחיד',
            shufflePlay: 'ניגון אקראי'
        },
        openText: 'פתיחה',
        closeText: 'סגירת פלייליסט',
        emptyText: '',
        clickToPlayText: 'ניגון',
        clickToPauseText: 'עצירה',
        nextTrackText: 'לשיר הבא',
        previousTrackText: 'לשיר הקודם',
        reloadText: 'הפעלה מחדש',
        volumeText: 'ווליום',
        playListsText: 'פלייליסט',
        toggleLyricText: '',
        toggleMiniModeText: 'סגירה',
        destroyText: '',
        downloadText: '',
        lightThemeText: '',
        darkThemeText: '',
        controllerTitle: '',
        switchThemeText: 'שינוי מצב בהירות',
        removeAudioListsText: '',
        emptyLyricText: '',
        clickToDeleteText: () => ``,
        audioTitle: '',
    }

    const options = {
        defaultPlayIndex: 0,
        audioLists: playlists[playlistIndex].songs,
        showDownload: false,
        locale: customLocale,
        remove: false,
        quietUpdate: true,
        clearPriorAudioLists: true,
        volumeFade: { fadeIn: 500, fadeOut: 500 },
        showMediaSession: true,
        defaultVolume: 0.5,
        autoPlay: true,
        sortableOptions: {
            delay: 50,
            delayOnTouchOnly: true
        }
    }

    return (
        <MainViewContext.Provider value={{
            viewedRefIndex, setViewedRefIndex,
            audioRef, handlePlaylistSwitch, playlistIndex
        }}>
            <ReactJkMusicPlayer
                ref={audioRef}
                {...options}
            />
            {props.children}
        </MainViewContext.Provider>
    )
}

export default MainViewContextProvider;