import React, { createContext } from "react";
import { isMobile } from "react-device-detect";

export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {

    return (
        <GlobalContext.Provider value={{
            isMobile,
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalContextProvider;