import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WebFont from "webfontloader";
import GlobalContextProvider from './contexts/GlobalContext';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-jinke-music-player/assets/index.css'
import "react-step-progress-bar/styles.css";
require('react-image-timeline/dist/timeline.css');

WebFont.load({
  google: {
    families: ["Heebo:400", "Rubik:400"],
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalContextProvider>
    <App />
  </GlobalContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
